import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';
import axios from 'axios';
import { config } from '@/config';
import { jwtDecode } from 'jwt-decode';
import { getAccessToken } from '@privy-io/react-auth';

const httpLink = createHttpLink({
  uri: config.graphqlEndpoint,
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  },
});

let authToken: string | null = null;

const authMiddleware = new ApolloLink((operation, forward) => {
  if (!authToken || isTokenExpired(authToken)) {
      refreshToken();
  }
  
  if (authToken) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${authToken}`,
      }
    });
  }
  return forward(operation);
});

export const apolloClient = new ApolloClient({
  link: authMiddleware.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
});

// Add axios instance configuration
export const axiosInstance = axios.create({
  baseURL: config.restEndpoint,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

const isTokenExpired = (token: string): boolean => {
  try {
    const decoded: { exp: number } = jwtDecode(token);
    // expires in 5 mins
    return decoded.exp * 1000 < Date.now() + 5 * 60 * 1000;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return true;
  }
};

export const refreshToken = async () => {
  const token = await getAccessToken();
  if(token) setAuthToken(token);
}


// Update token setter to also configure axios
export const setAuthToken = (token: string) => {
  authToken = token;
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

// Add token removal function (optional but recommended)
export const clearAuthToken = () => {
  authToken = null;
  delete axiosInstance.defaults.headers.common['Authorization'];
};