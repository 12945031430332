import { gql } from '@apollo/client';

export const GET_FILES = gql`
  query GetFiles {
    files {
      id
      key
      content
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_FILE = gql`
  mutation CreateFile($vault: String, $content: String!, $key: String!) {
    createFile(vault: $vault, content: $content, key: $key) {
      id
      key
      content
    }
  }
`;

// Add more queries and mutations as needed 

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      name
      createdAt
    }
  }
`;

export const GET_VAULTS = gql`
  query GetVaults {
    vaults {
      id
      name
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UserInput!) {
    updateUser(input: $input) {
      id
      name
      createdAt
    }
  }
`;

export const UPDATE_FILE = gql`
  mutation UpdateFile($id: ID!, $content: String!, $key: String!) {
    updateFile(id: $id, content: $content, key: $key) {
      id
      content
      key
      updatedAt
      createdAt
    }
  }
`;

export const DELETE_FILE = gql`
  mutation DeleteFile($id: ID!) {
    deleteFile(id: $id)
  }
`;
