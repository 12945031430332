import React from 'react';
import { ChevronLeft, Trash2, FolderPlus, Share2 } from 'lucide-react';
import { toast } from "sonner";
import { track } from '@/analytics';

import { Button } from "@/components/ui/button";
import { Note } from "@/lib/store";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useStore } from '@/lib/store';
import { isFeatureEnabled } from '@/analytics';

interface NoteSettingsProps {
  note: Note;
  onBack: () => void;
  onDelete: () => void;
}

export const NoteSettings: React.FC<NoteSettingsProps> = ({ note, onBack, onDelete }) => {
  const { deleteNote } = useStore();
  const handleDelete = async () => {
    track('Note Deleted', { note_id: note.id });
    await deleteNote(note.id);
    onDelete();
    toast.success("Note deleted successfully");
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center p-4 border-b">
        <Button variant="ghost" size="icon" onClick={onBack}>
          <ChevronLeft className="h-5 w-5" />
        </Button>
        <h1 className="text-xl ml-2">Settings</h1>
      </div>

      <div className="p-4 space-y-2">
        {isFeatureEnabled('additional_note_settings') && (
          <Button variant="outline" className="w-full justify-start" disabled>
            <FolderPlus className="h-4 w-4 mr-2" />
            Add to other collection
          </Button>
        )}
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button variant="outline" className="w-full justify-start text-destructive hover:text-destructive">
              <Trash2 className="h-4 w-4 mr-2" />
              Delete note
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Delete Note</AlertDialogTitle>
              <AlertDialogDescription>
                Are you sure you want to delete this note? This action cannot be undone.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={handleDelete} className="bg-destructive text-destructive-foreground hover:bg-destructive/90">
                Delete
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        {isFeatureEnabled('additional_note_settings') && (
          <Button variant="outline" className="w-full justify-start" disabled>
            <Share2 className="h-4 w-4 mr-2" />
          Create link to share
          </Button>
        )}
      </div>
    </div>
  );
}; 