import React from 'react';
import { Button } from "@/components/ui/button";
import { useRecordingState } from './useRecordingState';
import { Mic, StopCircle, Play, FileText, Loader2, RefreshCcw, Save } from 'lucide-react';
import { StateValue } from 'xstate';
import { useRecordingDuration } from './recordingService';

export const RecordingControls:  React.FC = () => {
  const {
    currentState,
    handleStartRecording,
    handlePauseRecording,
    handleResumeRecording,
    handleGenerateNotes,
    handleSave,
    handleRetry,
  } = useRecordingState();

  const duration = useRecordingDuration((state) => state.duration);

  const formatDuration = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  // Simplified button rendering based on state
  const renderButtons = () => {

    const getStateToSwitch = (state: StateValue): string => {
      if (typeof state === 'string') {
        return state;
      }
      const key = Object.keys(state)[0];
      return state[key] as string;
    };
    
    console.log('currentState', currentState);
    const stateToSwitch = getStateToSwitch(currentState);
    switch (stateToSwitch) {
      case 'idle':
        return (
          <Button
          onClick={async () => {
            await handleStartRecording();
          }}
          className="shadow-lg"
          size="lg"
        >
          <Mic className="h-4 w-4 mr-2" />
          Start Recording
          </Button>
        );
      
      case 'recording':
        return (
          <Button
        onClick={async () => {
          await handlePauseRecording();
        }}
        variant="destructive"
        className="shadow-lg"
        size="lg"
      >
        <StopCircle className="h-4 w-4 mr-2" />
        Stop Recording • {formatDuration(duration || 0)}
      </Button>
        );

      case 'paused':
        return (
           <div className="flex flex-col gap-2">
        <Button
          onClick={() => {
            handleResumeRecording();
          }}
          className="shadow-lg"
          size="lg"
        >
          <Play className="h-4 w-4 mr-2" />
          Resume • {formatDuration(duration || 0)}
        </Button>
        <Button
          onClick={handleGenerateNotes}
          variant="secondary"
          className="shadow-lg"
          size="lg"
        >
          <FileText className="h-4 w-4 mr-2" />
          Generate Notes
        </Button>
      </div>
        );
      
      
      case 'uploading':
        return (
          <Button disabled className="shadow-lg" size="lg">
            <Loader2 className="h-4 w-4 mr-2 animate-spin" />
            Uploading...
          </Button>
        );

      case 'transcribing':
        return (
          <Button disabled className="shadow-lg" size="lg">
            <Loader2 className="h-4 w-4 mr-2 animate-spin" />
            Transcribing...
          </Button>
        );

      case 'generating':
        return (
          <Button disabled className="shadow-lg" size="lg">
            <Loader2 className="h-4 w-4 mr-2 animate-spin" />
            Generating Notes...
          </Button>
        );

      case 'complete':
        return (
          <Button
            onClick={() => {
              handleSave();
            }}
            className="shadow-lg"
            size="lg"
          >
            <Save className="h-4 w-4 mr-2" />
            Save Note
          </Button>
        );
      
    case 'saving_failed':
    case 'upload_failed':
    case 'transcribing_failed':
    case 'generating_failed':
      return (
        <Button
            onClick={() => {
              handleRetry();
            }}
            className="shadow-lg"
            size="lg"
          >
            <RefreshCcw className="h-4 w-4 mr-2" />
            Retry Generating Notes
          </Button>
      );

    }
  };

  return (
    <div className="fixed bottom-20 left-1/2 transform -translate-x-1/2 flex gap-2 z-50">
      {renderButtons()}
    </div>
  );
};
