import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App.tsx'
import { initPostHog } from '@/analytics';

function injectMetaTags() {
  const tags = `
    <link rel="manifest" href="/manifest.json">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="theme-color" content="#000000">
    <link rel="apple-touch-icon" href="icons/icon-192x192.png">
  `;
  
  document.head.insertAdjacentHTML('beforeend', tags);
}

// Call this function before the React app renders
injectMetaTags();

// Add this line before the createRoot call in your main.tsx/index.tsx
document.addEventListener('DOMContentLoaded', injectMetaTags);

initPostHog();

createRoot(document.getElementById('root')!).render(
  <StrictMode>
      <App />
  </StrictMode>,
)
