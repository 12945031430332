import * as Sentry from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';

import { config } from '../config';

Sentry.init({
    dsn: config.sentryDSN,
    release: `muse@${config.version}`,
    

    integrations: [
      SentryReact.browserTracingIntegration(),
      // Sentry.replayIntegration(),
    ],

    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    // Add device information to Sentry events
    beforeSend(event) {
        if (config.device) {
            event.contexts = {
                ...event.contexts,
                device: config.device,
            };
        }
        return event;
    },
  },
  // Forward the init method from @sentry/react
  SentryReact.init,
);