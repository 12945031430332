import { usePrivy } from '@privy-io/react-auth';
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
  children: React.ReactNode;
}

export const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { authenticated } = usePrivy();

  if (!authenticated) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
}; 