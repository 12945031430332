import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PrivyProvider, usePrivy } from '@privy-io/react-auth';
import { ApolloProvider } from '@apollo/client';
import Landing from './pages/Landing';
import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';
import './App.css';
import { SidebarProvider } from "@/components/ui/sidebar"
import { AppSidebar } from "@/components/layouts/app-sidebar"
import { apolloClient } from '@/lib/apollo/client';
import { PrivateRoute } from '@/components/layouts/PrivateRoute';
import { Toaster } from "@/components/ui/sonner"
import { config } from '@/config';
import { identify } from '@/analytics';
import TabLayout from '@/components/layouts/TabLayout';
import { RecordingContext } from './components/capture/recordingService';
import SafeAreaLayout from '@/components/layouts/SafeAreaLayout';
import { useEffect } from 'react';
import { refreshToken } from '@/lib/apollo/client';
import * as Sentry from '@sentry/react';
// Create a wrapper component for the main content
function MainContent() {
  // const location = useLocation();
  // const isLanding = location.pathname === '/';
  const { authenticated } = usePrivy();
  
  useEffect(() => {
    if (!authenticated) {
      return;
    }

    const interval = setInterval(() => {
      try {
        refreshToken();
      } catch (error) {
        Sentry.captureException(error);
      }
    }, 30 * 60 * 1000); // 30 minutes in milliseconds

    return () => clearInterval(interval);
  }, [authenticated]);

  return (
    <>
      <main className="min-h-screen bg-background">
      <div className="container mx-auto py-4 mt-8">
        {/* {!isLanding && <SidebarTrigger />} */}
        <Routes>
          <Route path="/" element={<Landing />} />
          
          <Route path="/tab1" element={
            <PrivateRoute>
              <Tab1 />
            </PrivateRoute>
          } />
          
          <Route path="/tab2" element={
            <PrivateRoute>
              <Tab2 />
            </PrivateRoute>
          } />
          <Route path="/tab3" element={
            <PrivateRoute>
              <Tab3 />
            </PrivateRoute>
          } />
          <Route path="*" element={
            <PrivateRoute>
              <Tab2 />
            </PrivateRoute>
          } />
        </Routes>
      </div>
      </main>
      <Toaster />
    </>
  );
}

function App() {
  return (
    <PrivyProvider 
      appId={config.appID || import.meta.env.VITE_PRIVY_APP_ID}
      // clientId={config.privyClientID || import.meta.env.VITE_PRIVY_CLIENT_ID}
      config={{
        // Display email and wallet as login methods
        loginMethods: ['email', 'wallet'],
        // Customize Privy's appearance in your app
        appearance: {
          theme: 'light',
          accentColor: '#676FFF',
          // logo: 'https://your-logo-url',
        },
        // Create embedded wallets for users who don't have a wallet
        embeddedWallets: {
          createOnLogin: 'users-without-wallets',
        },
      }}
      onSuccess={(user, isNewUser) => {
        identify(user.id, {
          ...user,
          isNewUser,
        });
      }}
      >
      <RecordingContext.Provider>
        <ApolloProvider client={apolloClient}>
          <SafeAreaLayout>
          <BrowserRouter>
          <TabLayout>
            <SidebarProvider>
              <AppSidebar />
              <MainContent />
            </SidebarProvider>
            </TabLayout>
          </BrowserRouter>
          </SafeAreaLayout>
        </ApolloProvider>
      </RecordingContext.Provider>
    </PrivyProvider>
  );
}

export default App;
