import React, { useEffect } from 'react';
import { Plus } from 'lucide-react';

import { Button } from "@/components/ui/button";
import { useStore } from '@/lib/store';
import { track } from '@/analytics';
import {
  MDXEditor,
  listsPlugin,
  quotePlugin,
  headingsPlugin,
  linkPlugin,
  linkDialogPlugin,
  tablePlugin,
  thematicBreakPlugin,
  frontmatterPlugin,
  codeBlockPlugin,
  codeMirrorPlugin,
  directivesPlugin,
  AdmonitionDirectiveDescriptor,
  markdownShortcutPlugin,
  MDXEditorMethods
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"

import { RecordingControls } from '@/components/capture/recordingControls';
import { useRecordingState } from '@/components/capture/useRecordingState';
const Tab1: React.FC = () => {
  const {
    currentDraft,
    createNewDraft,
    updateDraft,
    isRecording,
    isPaused,
    noteTakerState,
  } = useStore();

  const { handleDiscard, isIdle } = useRecordingState();

  const placeholder = `# Take notes here while recording (optional)...`;

  const mdxEditorRef = useRef<MDXEditorMethods>(null)


  console.log("noteTakerState", noteTakerState);
  console.log("isRecording", isRecording);
  console.log("isPaused", isPaused);

  useEffect(() => {
    if (!currentDraft) {
      createNewDraft();
    }
    track("View Note Editor");
  }, [currentDraft, createNewDraft]);

  const handleContentChange = (content: string) => {
    updateDraft({ content });
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateDraft({ title: event.target.value });
  };

  const setMarkdown = (markdown: string) => {
    mdxEditorRef.current?.setMarkdown(markdown);
  };  

  const resetDraft = () => {
    handleDiscard();
    setMarkdown("");
  }

  const handleNewNoteClick = () => {
    if (noteTakerState === 'clean' && isIdle) {
      resetDraft();
    }
  };

  const renderAlertDialogContent = () => {
    if (noteTakerState === 'clean' && isIdle) {
      return null;
    }

    return (
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Unsaved Changes</AlertDialogTitle>
          <AlertDialogDescription>
            You have unsaved changes in your current note. Creating a new note will discard these changes. Are you sure you want to continue?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={resetDraft}>Continue</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    );
  };

  return (
    <div className="flex flex-col h-screen">
      {/* Header */}
    <div className="flex flex-col gap-4 p-4 border-b">
        <div className="flex justify-end">
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button
                variant="outline"
                size="sm"
                className="shrink-0"
                onClick={handleNewNoteClick}
              >
                <Plus className="h-4 w-4 mr-2" />
                New Note
              </Button>
            </AlertDialogTrigger>
            {renderAlertDialogContent()}
          </AlertDialog>
        </div>
        <div className="flex-1">
          <input
            type="text"
            placeholder="Note Title"
            value={currentDraft?.title || ''}
            onChange={handleTitleChange}
            className="w-full text-2xl font-bold bg-transparent border-none outline-none focus:ring-2 focus:ring-primary/20 rounded-md px-2 py-1"
          />
        </div>
      </div>

      {/* Main Editor Section - Full Width & Height */}
      <div className="flex-1 relative">
        <div className="absolute inset-0 bottom-16">
          <MDXEditor
            ref={mdxEditorRef}
            placeholder={placeholder}
            markdown={currentDraft?.content || ''}
            onChange={handleContentChange}
            plugins={[
              listsPlugin(),
              quotePlugin(),
              headingsPlugin({ allowedHeadingLevels: [1, 2, 3] }),
              linkPlugin(),
              linkDialogPlugin(),
              tablePlugin(),
              thematicBreakPlugin(),
              frontmatterPlugin(),
              codeBlockPlugin({ defaultCodeBlockLanguage: 'text' }),
              codeMirrorPlugin({
                codeBlockLanguages: {
                  js: 'JavaScript',
                  ts: 'TypeScript',
                  tsx: 'TypeScript React',
                  jsx: 'React',
                  css: 'CSS',
                  html: 'HTML',
                  json: 'JSON',
                  markdown: 'Markdown',
                  text: 'Plain Text'
                }
              }),
              directivesPlugin({ directiveDescriptors: [AdmonitionDirectiveDescriptor] }),
              markdownShortcutPlugin()
            ]}
            contentEditableClassName="prose dark:prose-invert max-w-none h-full"
          />
        </div>
        <RecordingControls />
      </div>
    </div>
  );
};

export default Tab1;