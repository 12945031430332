import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import axios, { AxiosResponse } from "axios";
import { VoiceRecorder } from 'capacitor-voice-recorder'
import { audioStorage } from '../../utils/audioStorage'
import { apolloClient, axiosInstance, refreshToken } from '../apollo/client';
import { GET_FILES, CREATE_FILE, GET_CURRENT_USER, UPDATE_USER, UPDATE_FILE, DELETE_FILE } from '../apollo/operations';
import { User } from '../apollo/types';
import * as Sentry from '@sentry/react';
import { toast } from "sonner"
import { track, log } from '@/analytics';

export interface Note {
  transcript?: string;
  id: string
  title: string
  content: string
  createdAt: number,
  updatedAt: number,
  status?: 'clean' | 'dirty' | 'generating' | 'complete'
  audioPath?: string
}

interface NotesFromRecordingResponse {
  title: string;
  content: string;
  insights: string;
  description: string;
}

interface NotesStore {
  currentDraft: Note | null
  noteTakerState: 'clean' | 'dirty' | 'generating' | 'complete'
  isRecording: boolean
  notes: Note[]
  currentUser: User | null
  isPaused: boolean
  currentAudioData: string | null
  audioData: string | null
  currentRecordingId: string | null
  transcriptionSuccessful: boolean
  
  startRecording: () => Promise<void>
  stopRecording: () => Promise<{ recordingId: string; base64Data: string } | null>
  addNote: (note: Note) => Promise<void>
  setAudioData: (audioData: string | null) => void
  setCurrentRecordingId: (currentRecordingId: string | null) => void
  setTranscriptionSuccessful: (transcriptionSuccessful: boolean) => void

  updateDraft: (draft: Partial<Note>) => void
  createNewDraft: () => void
  saveDraft: () => void
  setNoteTakerState: (state: 'clean' | 'dirty' | 'generating' | 'complete') => void
  updateNotes: () => void
  updateNote: (note: Partial<Note>) => Promise<void>
  fetchNotes: () => Promise<void>
  fetchCurrentUser: () => Promise<void> 
  updateCurrentUser: (user: Partial<User>) => Promise<void>
  togglePause: (isPaused?: boolean) => Promise<void>
  uploadAudio: (audioData: string, notes?: string) => Promise<{ id: string; }>
  generateTranscription: (recordingId: string) => Promise<{ transcript: string }>
  generateNotesFromRecording: (recordingId: string, title?: string, notes?: string) => Promise<NotesFromRecordingResponse>
  startTranscription: (fileId: string) => Promise<{ transcript: string }>
  getTranscript: (fileId: string) => Promise<{ transcript: string } | null>
  generateNotes: (fileId: string) => Promise<{
    title: string
    content: string
    insights: string
    description: string
  }>
  deleteNote: (fileId: string) => Promise<void>
  logout: () => void
}

const mapFile = (file: { id: string; key: string; content: string; createdAt: string; updatedAt: string; }) => ({
  id: file.id,
  title: file.key,
  content: file.content,
  createdAt: Number(file.createdAt),
  updatedAt: Number(file.updatedAt),
  status: 'complete' as const
});

export const useStore = create<NotesStore>()(
  persist(
    (set) => ({
      // Notes state
      currentDraft: null,
      noteTakerState: 'clean',
      isRecording: false,
      notes: [],
      currentUser: null,
      isPaused: false,
      currentAudioData: null,
      audioData: null,
      currentRecordingId: null,
      transcriptionSuccessful: false,

      // Recording methods
      getPermission: async () => {
        const permission = await VoiceRecorder.requestAudioRecordingPermission()
        return permission.value
      },

      startRecording: async () => {
        try {
          track('Requesting recording permission...');
          const permission = await VoiceRecorder.requestAudioRecordingPermission()
          log('Permission status:', { permission: permission.value });
          
          if (permission.value) {
            await VoiceRecorder.startRecording()
            log('Recording started');
            set({
              isRecording: true,
              isPaused: false,
              noteTakerState: 'dirty',
              currentAudioData: null
            })
          } else {
            track('Recording permission denied');
            toast.warning("Recording permission required")
          }
        } catch (error) {
          log('Recording error:', { error })
          Sentry.captureException(error);
        }
      },

      togglePause: async (isPaused?: boolean) => {
        try {
          set(state => {
            if (isPaused !== undefined) {
               if (isPaused) {
                VoiceRecorder.pauseRecording();
              } else {
                VoiceRecorder.resumeRecording();
              }
              return { isPaused }
            }

            if (state.isRecording) {
              if (state.isPaused) {
                VoiceRecorder.resumeRecording()
              } else {
                VoiceRecorder.pauseRecording()
              }
              return { isPaused: !state.isPaused }
            }
            return state
          })
        } catch (error) {
          log('Failed to toggle pause:', { error })
          Sentry.captureException(error);
        }
      },

      stopRecording: async () => {
        try {
          track('Stopping recording...');
          const result = await VoiceRecorder.stopRecording()
          log('Recording stopped, data length:', { length: result.value.recordDataBase64.length });
          
          const recordingId = Date.now().toString()
          
          log('Saving audio with ID:', { recordingId });
          await audioStorage.saveAudio(recordingId, result.value.recordDataBase64)
          
          set((state) => {
            const newState = { 
              isRecording: false,
              currentAudioData: result.value.recordDataBase64
            }
            
            if (state.currentDraft) {
              log('Updating draft with new audio snippet');
              const updatedDraft: Note = {
                ...state.currentDraft,
                updatedAt: new Date().getTime(),
                audioPath: recordingId
              }
              return {
                ...newState,
                currentDraft: updatedDraft
              }
            }
            return newState
          })
          
          return {
            recordingId,
            base64Data: result.value.recordDataBase64
          }
        } catch (error) {
          log('Stop recording error:', { error })
          Sentry.captureException(error);
          set({ isRecording: false, isPaused: false })
          return null
        }
      },

      setAudioData: (audioData: string | null) => set({ audioData }),
      setCurrentRecordingId: (currentRecordingId: string | null) => set({ currentRecordingId }),
      setTranscriptionSuccessful: (transcriptionSuccessful: boolean) => set({ transcriptionSuccessful }),

      // Notes methods
      createNewDraft: () => {
        track('Creating new draft...');
        const newDraft: Note = {
          id: Date.now().toString(),
          title: '',
          content: '',
          createdAt: new Date().getTime(),
          updatedAt: new Date().getTime(),
        }
        set({ 
          currentDraft: newDraft,
          noteTakerState: 'clean'
        })
      },

      addNote: async (note) => {
        try {
          track('Adding note...');
          await refreshToken();
          const { data } = await apolloClient.mutate({
            mutation: CREATE_FILE,
            variables: {
              content: note.content,
              key: note.title,
              vault: null
            }
          });

          set((state) => {
            const updatedNotes = [...state.notes, {
              id: data.createFile.id,
              title: data.createFile.key,
              content: data.createFile.content,
              createdAt: new Date().getTime(),
              updatedAt: new Date().getTime(),
              status: 'complete' as const
            }].sort((a: Note, b: Note) => b.updatedAt - a.updatedAt);

            return {
              ...state,
              notes: updatedNotes
            };
          });
        } catch (error) {
          log('Error creating note:', { error });
          Sentry.captureException(error);
        }
      },

      updateDraft: (draft) => set((state) => {
        track('Updating draft...');
        if (!state.currentDraft) { log('currentNote not found'); return state }
        
        const updatedNote = {
          ...state.currentDraft,
          ...draft,
          status: 'dirty' as const,
          updatedAt: new Date().getTime()
        }

        return {
          ...state,
          noteTakerState: 'dirty',
          currentDraft: updatedNote
        }
      }),

      saveDraft: () => set((state) => {
        track('Saving draft...');
        const { currentDraft } = state;
        if (!currentDraft) { log('currentNote not found'); return state }

        const savedNote = {
          ...currentDraft,
          status: 'complete' as const,
          updatedAt: new Date().getTime()
        }

        return {
          ...state,
          notes: [...state.notes, savedNote].sort((a: Note, b: Note) => b.updatedAt - a.updatedAt),
          currentDraft: null
        }
      }),

      setNoteTakerState: (state) => set({ noteTakerState: state }),

      updateNotes: () => set((state) => ({
        ...state,
        notes: state.notes.sort((a, b) => b.updatedAt - a.updatedAt)
      })),

      fetchNotes: async () => {
        try {
          const { data } = await apolloClient.query({
            query: GET_FILES
          });

          const notes = data.files.map((file: { id: string; key: string; content: string; createdAt: string; updatedAt: string; }) => ({
            id: file.id,
            title: file.key,
            content: file.content,
            createdAt: Number(file.createdAt),
            updatedAt: Number(file.updatedAt),
            status: 'complete' as const
          }));

          set((state) => ({
            ...state,
            notes: notes.sort((a: Note, b: Note) => b.updatedAt - a.updatedAt)
          }));
        } catch (error) {
          if (error instanceof Error && error.message === 'Not authenticated') {
            await refreshToken();
          }
          console.error('Error fetching notes:', error);
          Sentry.captureException(error);
        }
      },

      fetchCurrentUser: async () => {
        track('Fetching current user...');
        const { data } = await apolloClient.query({ query: GET_CURRENT_USER });
        set({ currentUser: data.getCurrentUser });
      },

      updateCurrentUser: async (user) => {
        track('Updating current user...');
        const { data } = await apolloClient.mutate({ mutation: UPDATE_USER, variables: { input: user } });
        set({ currentUser: data.updateUser });
      },

      updateNote: async (note) => {
        track('Updating note...');
        const { data } = await apolloClient.mutate({ mutation: UPDATE_FILE, variables: { id: note.id, content: note.content, key: note.title } });
        set((state) => ({
          ...state,
          notes: state.notes.map(n => n.id === note.id ? mapFile({...n, ...data.updateFile}) : n)
        }));
      },

      uploadAudio: async (audioData, notes) => {
        track('Uploading audio...');
        const formData = new FormData();
        const url = `data:audio/wav;base64,${audioData}`;
        const audioBlob = await fetch(url).then(r => r.blob());
        formData.append('audio', audioBlob, 'recording.wav');
        if (notes) formData.append('notes', notes);

        const { data } = await axiosInstance.post('/upload', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        return data;
      },

      startTranscription: async (fileId) => {
        track('Starting transcription...');
        const { data } = await axiosInstance.post(`/transcribe/${fileId}`);
        return data;
      },

      getTranscript: async (fileId) => {
        track('Getting transcript...');
        try {
          const { data } = await axiosInstance.get(`/transcript/${fileId}`);
          return data;
        } catch (error) {
           
          if (axios.isAxiosError(error) && error.response?.status === 404) {
            return null;
          }
          Sentry.captureException(error);
          return null;
        }
      },

      generateNotes: async (fileId) => {
        track('Generating notes...');
        const { data } = await axiosInstance.post(`/notes/${fileId}`);
        return data;
      },

      generateTranscription: async (recordingId: string) => {
        track('Generating transcription...');
        const { data } = await axiosInstance.post(`/transcriptFromRecording/${recordingId}`);
        return data;
      },

      generateNotesFromRecording: async (
        recordingId: string,
        title?: string,
        notes?: string
      ): Promise<NotesFromRecordingResponse> => {
        track('Generating notes from recording...');
        try {
          const response: AxiosResponse<NotesFromRecordingResponse> = await axiosInstance.post(
            `/notesFromRecording/${recordingId}`,
            {
              title,
              notes
            }
          );
          return response.data;
        } catch (error) {
          console.error('Error generating notes:', error);
          Sentry.captureException(error);
          throw error;
        }
      },

      deleteNote: async (fileId: string) => {
        track('Deleting note...');
        const { data } = await apolloClient.mutate({ mutation: DELETE_FILE, variables: { id: fileId } });
        if (data.deleteFile) {
          set(state => ({
            notes: state.notes.filter(note => note.id !== fileId)
          }));
        }
      },

      logout: () => set({
          notes: [],
        currentDraft: null,
        noteTakerState: 'clean',
        isRecording: false,
        currentUser: null,
        isPaused: false,
        currentAudioData: null
      }),
    }),

    {
      name: 'app-storage',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        notes: state.notes.map(note => ({
          id: note.id,
          title: note.title,
          content: note.content,
          createdAt: note.createdAt,
          updatedAt: note.updatedAt,
          status: note.status,
          audioPath: note.audioPath,
        })),
        currentDraft: state.currentDraft ? {
          id: state.currentDraft.id,
          title: state.currentDraft.title,
          content: state.currentDraft.content,
          createdAt: state.currentDraft.createdAt,
          updatedAt: state.currentDraft.updatedAt,
          status: state.currentDraft.status,
          audioPath: state.currentDraft.audioPath,
        } : null,
        noteTakerState: state.noteTakerState,
        currentAudioData: null,
        isPaused: false
      })
    }
  )
)



