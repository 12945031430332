import React, { useState } from 'react';
import { ChevronLeft, Settings, Pencil, Save } from 'lucide-react';
import { format } from 'date-fns';
import { toast } from 'sonner';
import {
  MDXEditor,
  listsPlugin,
  quotePlugin,
  headingsPlugin,
  linkPlugin,
  linkDialogPlugin,
  tablePlugin,
  thematicBreakPlugin,
  frontmatterPlugin,
  codeBlockPlugin,
  codeMirrorPlugin,
  directivesPlugin,
  AdmonitionDirectiveDescriptor,
  markdownShortcutPlugin,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';

import { track } from '@/analytics';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Note } from "@/lib/store";
import * as Sentry from '@sentry/react';


import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"

interface NoteDetailProps {
  note: Note;
  onBack: () => void;
  onSettings: () => void;
  handleUpdate?: (note: Note) => Promise<void>;
}

export const NoteDetail: React.FC<NoteDetailProps> = ({ note, onBack, onSettings, handleUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(note.content);
  const [editedTitle, setEditedTitle] = useState(note.title);
  const handleSettingsClick = () => {
    track('Note Settings Opened', { note_id: note.id });
    onSettings();
  };

  const handleEditToggle = async () => {
    if (isEditing) {
      track('Note Save Started', { note_id: note.id });
      try {
        await handleUpdate?.({
          ...note,
          content: editedContent,
          title: editedTitle,
          updatedAt: Date.now()
        });
        track('Note Saved', { note_id: note.id });
        toast.success('Note saved');
      } catch (error) {
        toast.error('Failed to save note');
        Sentry.captureException(error);

      }
    } else {
      track('Note Edit Started', { note_id: note.id });
    }
    setIsEditing(!isEditing);
  };
  
  const renderButtons = () => {
    return (
      <Button 
        className="shadow-lg"
        onClick={handleEditToggle}
      >
        {isEditing ? (
          <>
            <Save className="h-4 w-4 mr-2" />
            Save
          </>
        ) : (
          <>
            <Pencil className="h-4 w-4 mr-2" />
            Edit
          </>
        )}
      </Button>
    );
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center p-4 border-b">
        <Button variant="ghost" size="icon" onClick={onBack}>
          <ChevronLeft className="h-5 w-5" />
        </Button>
        <Button variant="ghost" size="icon" onClick={handleSettingsClick}>
          <Settings className="h-5 w-5" />
        </Button>
      </div>

      <div className="p-4 flex-1 overflow-auto">
        <div className="mb-6">
          {isEditing ? (
            <Input
              type="text"
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              className="text-xl font-bold mb-2 w-full bg-transparent border-none outline-none focus:ring-2 focus:ring-primary/20 rounded-md px-2 py-1"
            />
          ) : (
            <h1 className="text-xl font-bold mb-2">{editedTitle}</h1>
          )}
          <div className="flex gap-2">
            <span className="px-3 py-1 rounded-full bg-secondary text-sm">
              {format(new Date(note.updatedAt), 'EEE MMM dd, yyyy')}
            </span>
          </div>
        </div>

        <div className="space-y-4">
          <MDXEditor
              markdown={editedContent}
              readOnly={!isEditing}
              onChange={isEditing ? setEditedContent : undefined}
              plugins={[
                listsPlugin(),
                quotePlugin(),
                headingsPlugin({ allowedHeadingLevels: [1, 2, 3] }),
                linkPlugin(),
                linkDialogPlugin(),
                tablePlugin(),
                thematicBreakPlugin(),
                frontmatterPlugin(),
                codeBlockPlugin({ defaultCodeBlockLanguage: 'text' }),
                codeMirrorPlugin({
                  codeBlockLanguages: {
                    js: 'JavaScript',
                    ts: 'TypeScript',
                    tsx: 'TypeScript React',
                    jsx: 'React',
                    css: 'CSS',
                    html: 'HTML',
                    json: 'JSON',
                    markdown: 'Markdown',
                    text: 'Plain Text'
                  }
                }),
                directivesPlugin({ directiveDescriptors: [AdmonitionDirectiveDescriptor] }),
                markdownShortcutPlugin()
              ]}
              contentEditableClassName="prose dark:prose-invert max-w-none h-full"
            />
        </div>

        {note.transcript && (
        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="item-1">
            <AccordionTrigger>Transcript</AccordionTrigger>
            <AccordionContent>
              {note.transcript}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        )}
      </div>

      <div className="fixed bottom-20 left-1/2 transform -translate-x-1/2 flex gap-2 z-50">
      {renderButtons()}
    </div>

      {/* <div className="pt-4 border-t">
        <Input
          disabled={true}
          placeholder="Ask about this note..."
          className="w-full text-muted-foreground"
          />
      </div> */}
    </div>
  );
}; 