import { useNavigate } from 'react-router-dom';
import { Sparkles } from 'lucide-react';
import { usePrivy } from "@privy-io/react-auth";
import { useEffect } from 'react';

import { Button } from "@/components/ui/button";
import { useStore } from '@/lib/store';
import { setAuthToken } from '@/lib/apollo/client';
import { identify } from '@/analytics';

const Landing: React.FC = () => {
  const navigate = useNavigate();
  const { ready, authenticated, login } = usePrivy();
  const { fetchCurrentUser } = useStore();

  useEffect(() => {
    if (!ready) return;
    
    if (authenticated) {
      const token = localStorage.getItem('privy:token');
      const jwt = token ? JSON.parse(token) : null;
      if (jwt) {
        const jwtPayload = JSON.parse(atob(jwt.split('.')[1]));
        const userId = jwtPayload.sub;

        setAuthToken(jwt);
        identify(userId);
      }
      fetchCurrentUser();
      navigate('/tab2');
    }
  }, [ready, authenticated, navigate, fetchCurrentUser]);

  if (!ready) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-background to-secondary/20 flex flex-col items-center justify-center p-6">
      {/* Logo and Title */}
      <div className="flex items-center gap-2 mb-4">
        <Sparkles className="h-8 w-8 text-primary" />
        <h1 className="text-4xl font-bold tracking-tight">Muse</h1>
      </div>
      
      {/* Tagline */}
      <p className="text-xl text-muted-foreground mb-12 text-center max-w-md">
        Transform your conversations into structured notes with AI.
      </p>
      
      {/* Features List */}
      <div className="grid gap-4 mb-12 text-center">
        <p className="text-sm text-muted-foreground">
          🎙️ Record your great conversations
        </p>
        <p className="text-sm text-muted-foreground">
          ✨ AI-powered note generation
        </p>
        <p className="text-sm text-muted-foreground">
          📝 Rich text editing
        </p>
      </div>
      
      {/* Login Button */}
      <Button 
        variant="default" 
        size="lg"
        className="px-8 py-6 text-lg shadow-lg"
        onClick={login}
      >
        Get Started
      </Button>
      
      {/* Footer Text */}
      <p className="mt-8 text-xs text-center text-muted-foreground max-w-sm">
        By continuing, you agree to our Terms of Service and Privacy Policy
      </p>
    </div>
  );
};

export default Landing;
