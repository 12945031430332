/* eslint-disable @typescript-eslint/no-explicit-any */
import { createActorContext } from '@xstate/react';
import { create } from 'zustand';
import { recordingMachine } from './recordingMachine';

interface RecordingDurationState {
    duration: number;
    startTime: number | null;
    accumulatedDuration: number;
    startDuration: () => void;
    pauseDuration: () => void;
    resetDuration: () => void;
}

export const RecordingContext = createActorContext(recordingMachine); 

export const useRecordingDuration = create<RecordingDurationState>((set) => ({
  duration: 0,
  startTime: null,
  accumulatedDuration: 0,
  startDuration: () => {
    console.log('Starting duration');
    set({ startTime: Date.now() });
    
    // Start the interval
    const interval = setInterval(() => {
      set((state) => ({
        duration: state.accumulatedDuration + 
          (state.startTime ? Math.floor((Date.now() - state.startTime) / 1000) : 0)
      }));
    }, 1000);

    // Store the interval ID in window to clear it later
    (window as any).__durationInterval = interval;
  },
  pauseDuration: () => {
    console.log('Pausing duration');
    clearInterval((window as any).__durationInterval);
    set((state) => ({
      startTime: null,
      accumulatedDuration: state.duration
    }));
  },
  resetDuration: () => {
    console.log('Resetting duration');
    clearInterval((window as any).__durationInterval);
    set({ 
      duration: 0, 
      startTime: null,
      accumulatedDuration: 0 
    });
  },
}));
