import { Device, DeviceInfo } from '@capacitor/device';
import pkg from '../package.json';


interface DeviceContext {
    model: string;
    manufacturer: string;
    name?: string;
    simulator?: boolean;
    device_type?: string;
    memory_size?: number;
    free_storage?: number;
    storage_size?: number;
    family?: string;
    cpu_description?: string;
    model_id?: string;
}

interface EnvConfig {
    production: boolean;
    appID: string;
    privyClientID: string;
    graphqlEndpoint: string;
    restEndpoint: string;
    sentryDSN: string;
    posthogApiKey: string;
    posthogHost: string;
    version: string;
    buildNumber: string;
    device?: Partial<DeviceContext>;
    featureFlags: FeatureFlags;
}

interface FeatureFlags {
    privacy_and_security_section: boolean;
    additional_note_settings: boolean;
}

// Initialize device info
const getDeviceInfo = async () => {
    try {
        const info = await Device.getInfo();
        return {
            platform: info.platform,
            model: info.model,
            operatingSystem: info.operatingSystem,
            osVersion: info.osVersion,
            manufacturer: info.manufacturer,
            isVirtual: info.isVirtual,
            webViewVersion: info.webViewVersion,
        };
    } catch (error) {
        console.warn('Failed to get device info:', error);
        return undefined;
    }
};

export const config: EnvConfig = {
    production: import.meta.env.NODE_ENV === 'production',
    version: pkg.version,
    buildNumber: import.meta.env.VITE_BUILD_NUMBER || 'dev',
    appID: import.meta.env.VITE_PRIVY_APP_ID,
    privyClientID: import.meta.env.VITE_PRIVY_CLIENT_ID,
    graphqlEndpoint: import.meta.env.VITE_GRAPHQL_ENDPOINT,
    restEndpoint: import.meta.env.VITE_REST_ENDPOINT,
    sentryDSN: import.meta.env.VITE_SENTRY_DSN,
    posthogApiKey: import.meta.env.VITE_POSTHOG_API_KEY,
    posthogHost: import.meta.env.VITE_POSTHOG_HOST || 'https://app.posthog.com',
    featureFlags: {
        privacy_and_security_section: false,
        additional_note_settings: false,
    }
};

// Initialize device info
getDeviceInfo().then(deviceInfo => {
    if (deviceInfo) {
        config.device = mapDeviceInfoToContext(deviceInfo);
    }
});


export function mapDeviceInfoToContext(deviceInfo: DeviceInfo): DeviceContext {
    const context: DeviceContext = {
        name: deviceInfo.name,
        model: deviceInfo.model,
        manufacturer: deviceInfo.manufacturer,
        simulator: deviceInfo.isVirtual,
        
        // Map platform-specific fields
        device_type: deviceInfo.platform,
        
        // Map memory info if available
        memory_size: deviceInfo.memUsed,
        free_storage: deviceInfo.realDiskFree ?? deviceInfo.diskFree,
        storage_size: deviceInfo.realDiskTotal ?? deviceInfo.diskTotal,
        
        // Operating system info
        family: deviceInfo.operatingSystem,
        
        // Browser/webview info
        cpu_description: deviceInfo.webViewVersion,
        model_id: undefined as string | undefined,
    };

    // Add platform-specific SDK/version information
    if (deviceInfo.platform === 'ios' && deviceInfo.iOSVersion) {
        context.model_id = `iOS ${deviceInfo.iOSVersion}`;
    } else if (deviceInfo.platform === 'android' && deviceInfo.androidSDKVersion) {
        context.model_id = `Android SDK ${deviceInfo.androidSDKVersion}`;
    }

    // Remove undefined values
    return Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(context).filter(([_, value]) => value !== undefined)
    ) as DeviceContext;
}
