import React from 'react';
import { Mic, List, Settings } from "lucide-react"

import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from "@/components/ui/button";

const TabLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isLanding = location.pathname === '/';


  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-1">
        <div className="pb-16">
          {children}
        </div>
      </main>
      
      {!isLanding && (
      <div className="fixed bottom-0 left-0 right-0 border-t bg-background">
        <div className="flex justify-around p-2">
          <Button 
            variant={location.pathname === '/tab1' ? 'default' : 'ghost'}
            onClick={() => navigate('/tab1')}
          >
            <Mic size={16} className="mr-1" />
            Capture
          </Button>
          <Button 
            variant={location.pathname === '/tab2' ? 'default' : 'ghost'}
            onClick={() => navigate('/tab2')}
          >
            <List size={16} className="mr-1" />
            Notes
          </Button>
          <Button 
            variant={location.pathname === '/tab3' ? 'default' : 'ghost'}
            onClick={() => navigate('/tab3')}
          >
            <Settings size={16} className="mr-1" />
            Settings
          </Button>
        </div>
        </div>
      )}
    </div>
  );
};

export default TabLayout; 