import React from 'react';
import { useEffect, useState } from 'react';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';

const SafeAreaLayout = ({ children }: { children: React.ReactNode }) => {
  const [insets] = useState({ top: 0, bottom: 0 });

  useEffect(() => {
    const setupSafeArea = async () => {
      try {
        // Get platform info
        const platform = Capacitor.getPlatform();
        const isIOS = platform === 'ios';

        if (isIOS) {
          // Show light status bar on iOS
          await StatusBar.setStyle({ style: Style.Default });
        }

        // Add CSS variables for safe area insets
        document.documentElement.style.setProperty(
          '--sat',
          `env(safe-area-inset-top, ${insets.top}px)`
        );
        document.documentElement.style.setProperty(
          '--sab',
          `env(safe-area-inset-bottom, ${insets.bottom}px)`
        );
      } catch (error) {
        console.error('Error setting up safe area:', error);
      }
    };

    setupSafeArea();
  }, [insets]);

  return (
    <div className="flex flex-col min-h-screen bg-white">
      {/* Status bar spacer */}
      <div className="h-[var(--sat)] bg-gray-900" />
      
      {/* Main content area */}
      <main className="flex-1 overflow-auto">
        {children}
      </main>
      
      {/* Bottom safe area spacer */}
      <div className="h-[var(--sab)] bg-white" />
    </div>
  );
};

export default SafeAreaLayout;