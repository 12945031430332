/* eslint-disable @typescript-eslint/no-explicit-any */
import { PostHog } from 'posthog-node'
import * as Sentry from '@sentry/capacitor';
import { config } from '../config';
import { logger } from './logger';

let client: PostHog | undefined;
let userId: string | undefined;

export const initPostHog = () => {
  const apiKey = config.posthogApiKey;
  const host = config.posthogHost;
  
  if (!apiKey) {
    console.warn('PostHog API key not found');
    return;
  }

  client = new PostHog(apiKey,
    { host, bootstrap:
      {
        distinctId: userId,
        featureFlags: { ...config.featureFlags },
      }
    }
  );
}

export const identify = (currentUserId: string, traits?: Record<string, any>) => {
  userId = currentUserId;
  client?.identify({
    distinctId: userId,
    properties: {
      ...traits,
      ...config.device,
    }
  });
  Sentry.setUser({ id: userId });
  logger.info(`Identified user ${userId}`);
}

export const track = (event: string, properties?: Record<string, any>) => {
  if (!userId) return;

  client?.capture({
    distinctId: userId,
    event,
    properties
  });

  Sentry.addBreadcrumb({
    category: "analytics",
    message: "Tracked event " + event,
    level: "info",
  });
  logger.info(`Event ${event}`);
}

export const page = (name?: string, properties?: Record<string, any>) => {
  if (!userId) return;

  client?.capture({
    distinctId: userId,
    event: '$pageview',
    properties: {
      ...properties,
      page_name: name
    }
  });
  logger.info(`Page ${name}`);
}

export const isFeatureEnabled = (key: string) => {
  if (!client || !userId) return false;
  return client.isFeatureEnabled(key, userId);
}