import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { usePrivy } from "@privy-io/react-auth";
import { useNavigate } from 'react-router-dom';
import { useStore } from '@/lib/store';
import { clearAuthToken } from '@/lib/apollo/client';
import { User, Mail, Wallet, LogOut, ChevronRight, Edit2 } from 'lucide-react';
import { Separator } from "@/components/ui/separator";
import * as Sentry from '@sentry/react';
import { toast } from "sonner";
import { config } from '@/config';
import { track, isFeatureEnabled } from '@/analytics';

const Tab3: React.FC = () => {
  const { ready, user, logout } = usePrivy();
  const { currentUser, updateCurrentUser, logout: logoutStore } = useStore();  
  const [isEditing, setIsEditing] = useState(false);
  const [editForm, setEditForm] = useState({
    name: currentUser?.name || ''
  });

  const navigate = useNavigate();
  
  useEffect(() => {
    track("View Settings");
  }, []);
  
  const handleLogout = async () => {
    try {
      track("User Logout");
      await logout();
      logoutStore();
      clearAuthToken();
      Sentry.setUser(null);
      navigate('/');
      toast.success("Logged out successfully");
    } catch (error) {
      console.error('Logout failed:', error);
      Sentry.captureException(error);
    }
  };

  const handleSaveProfile = () => {
    track("Update Profile", {
      field_updated: "name"
    });
    updateCurrentUser({ name: editForm.name });
    setIsEditing(false);
  };

  const handleFeedbackClick = () => {
    track("Click Send Feedback");
    window.location.href = `mailto:feedback@tinycloud.xyz?subject=Muse feedback [${config.version} (build ${config.buildNumber})]`;
  };

  const renderPrivacyAndSecurity = () => {
    if (isFeatureEnabled('privacy_and_security_section') ) {
      return (<Card className="cursor-pointer hover:bg-accent/50 transition-colors">
        <CardHeader>
          <div className="flex items-center justify-between">
            <CardTitle className="flex items-center gap-2 text-base font-medium">
              Privacy & Security
            </CardTitle>
            <ChevronRight className="h-5 w-5 text-muted-foreground" />
          </div>
        </CardHeader>
      </Card>)
    }
  }

  if (!ready) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="container max-w-2xl mx-auto p-4 space-y-6">
      <div className="flex items-center justify-center">
        <h1 className="text-2xl font-bold">Settings</h1>
      </div>

      {/* Profile Section */}
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <User className="h-5 w-5" />
            Profile
          </CardTitle>
          <CardDescription>
            Manage your account settings and preferences
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          {/* Account Type */}
          <div className="flex items-center justify-between py-2">
            {user?.email?.address ? (
              <div className="flex items-center gap-3">
                <Mail className="h-4 w-4 text-muted-foreground" />
                <div>
                  <div className="font-medium">{user.email.address}</div>
                  <div className="text-sm text-muted-foreground">Email Login</div>
                </div>
              </div>
            ) : (
              <div className="flex items-center gap-3">
                <Wallet className="h-4 w-4 text-muted-foreground" />
                <div>
                  <div className="font-medium">
                    {user?.wallet?.address?.slice(0, 6)}...{user?.wallet?.address?.slice(-4)}
                  </div>
                  <div className="text-sm text-muted-foreground">Wallet Address</div>
                </div>
              </div>
            )}
          </div>

          <Separator />

          {/* Name Field */}
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <div className="font-medium">Display Name</div>
                <div className="text-sm text-muted-foreground">
                  {isEditing ? 'Enter your preferred display name' : currentUser?.name || 'Not set'}
                </div>
              </div>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setIsEditing(!isEditing)}
              >
                <Edit2 className="h-4 w-4" />
              </Button>
            </div>

            {isEditing && (
              <div className="space-y-2">
                <Input
                  value={editForm.name}
                  onChange={(e) => setEditForm({ ...editForm, name: e.target.value })}
                  placeholder="Enter your name"
                  className="max-w-sm"
                />
                <div className="flex gap-2">
                  <Button 
                    variant="default"
                    onClick={handleSaveProfile}
                  >
                    Save Changes
                  </Button>
                  <Button 
                    variant="outline"
                    onClick={() => {
                      setEditForm({ name: currentUser?.name || '' });
                      setIsEditing(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            )}
          </div>
        </CardContent>
      </Card>

      {/* Privacy & Security Card */}
      {renderPrivacyAndSecurity()}

      {/* Feedback Card */}
      <Card className="cursor-pointer hover:bg-accent/50 transition-colors">
        <CardHeader>
          <div 
            className="flex items-center justify-between"
            onClick={handleFeedbackClick}
          >
            <CardTitle className="flex items-center gap-2 text-base font-medium">
              <Mail className="h-5 w-5" />
              Send Feedback
            </CardTitle>
            <ChevronRight className="h-5 w-5 text-muted-foreground" />
          </div>
        </CardHeader>
      </Card>

      {/* Logout Section */}
      <Card className="border-destructive/50">
        <CardContent className="p-6">
          <div className="flex items-center justify-between">
            <div className="space-y-1">
              <h3 className="font-medium">Log out</h3>
              <p className="text-sm text-muted-foreground">
                Sign out of your account
              </p>
            </div>
            <Button 
              variant="destructive"
              onClick={handleLogout}
              className="gap-2"
            >
              <LogOut className="h-4 w-4" />
              Log Out
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Tab3;