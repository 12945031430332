import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const CHUNK_SIZE = 1024 * 1024; // 1MB chunks
const MAX_CACHE_SIZE = 50 * 1024 * 1024; // 50MB cache limit
const CACHE_EXPIRY = 24 * 60 * 60 * 1000; // 24 hours

interface AudioMetadata {
  id: string;
  chunks: string[];
  size: number;
  format: string;
  lastAccessed: number;
  duration?: number;
}

interface AudioStorageState {
  metadata: Record<string, AudioMetadata>;
  totalSize: number;
}

const useAudioStorageStore = create(
  persist<AudioStorageState>(
    () => ({
      metadata: {},
      totalSize: 0,
    }),
    {
      name: 'audio-storage-metadata',
    }
  )
);

export class AudioStorageManager {
  private static instance: AudioStorageManager;
  private db: IDBDatabase | null = null;
  private dbName = 'AudioDB';
  private dbVersion = 2;

  private constructor() {}

  static getInstance(): AudioStorageManager {
    if (!AudioStorageManager.instance) {
      AudioStorageManager.instance = new AudioStorageManager();
    }
    return AudioStorageManager.instance;
  }

  private async initDB(): Promise<IDBDatabase> {
    if (this.db) return this.db;

    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.dbVersion);

      request.onerror = () => reject(request.error);

      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        if (!db.objectStoreNames.contains('chunks')) {
          db.createObjectStore('chunks');
        }
      };

      request.onsuccess = () => {
        this.db = request.result;
        resolve(this.db);
      };
    });
  }

  private async saveChunk(chunkId: string, data: string): Promise<void> {
    const db = await this.initDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['chunks'], 'readwrite');
      const store = transaction.objectStore('chunks');
      const request = store.put(data, chunkId);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve();
    });
  }

  private async getChunk(chunkId: string): Promise<string | null> {
    const db = await this.initDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['chunks'], 'readonly');
      const store = transaction.objectStore('chunks');
      const request = store.get(chunkId);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);
    });
  }

  async saveAudio(id: string, base64Data: string, format: string = 'wav'): Promise<void> {
    console.log(`Saving audio ${id}, data length: ${base64Data.length}`);
    const chunks: string[] = [];
    const size = base64Data.length;
    let offset = 0;

    // Split data into chunks
    while (offset < base64Data.length) {
      const chunkId = `${id}_${chunks.length}`;
      const chunk = base64Data.slice(offset, offset + CHUNK_SIZE);
      console.log(`Saving chunk ${chunkId}, size: ${chunk.length}`);
      await this.saveChunk(chunkId, chunk);
      chunks.push(chunkId);
      offset += CHUNK_SIZE;
    }

    // Update metadata
    useAudioStorageStore.setState((state) => ({
      metadata: {
        ...state.metadata,
        [id]: {
          id,
          chunks,
          size,
          format,
          lastAccessed: Date.now(),
        },
      },
      totalSize: state.totalSize + size,
    }));

    // Cleanup if needed
    await this.cleanupIfNeeded();
  }

  async getAudio(id: string): Promise<string | null> {
    console.log(`Retrieving audio ${id}`);
    const state = useAudioStorageStore.getState();
    const metadata = state.metadata[id];

    if (!metadata) {
      console.log(`No metadata found for audio ${id}`);
      return null;
    }

    // Update last accessed time
    useAudioStorageStore.setState((state) => ({
      metadata: {
        ...state.metadata,
        [id]: {
          ...state.metadata[id],
          lastAccessed: Date.now(),
        },
      },
    }));

    // Retrieve and concatenate chunks
    const chunks = await Promise.all(
      metadata.chunks.map((chunkId) => this.getChunk(chunkId))
    );

    return chunks.join('');
  }

  private async cleanupIfNeeded(): Promise<void> {
    const state = useAudioStorageStore.getState();
    if (state.totalSize <= MAX_CACHE_SIZE) return;

    const now = Date.now();
    const metadata = Object.values(state.metadata)
      .sort((a, b) => a.lastAccessed - b.lastAccessed);

    let freedSpace = 0;
    const toDelete: string[] = [];

    for (const audio of metadata) {
      if (state.totalSize - freedSpace <= MAX_CACHE_SIZE) break;
      if (now - audio.lastAccessed < CACHE_EXPIRY) continue;

      freedSpace += audio.size;
      toDelete.push(audio.id);
    }

    // Delete chunks and metadata for selected audio files
    await Promise.all(
      toDelete.map(async (id) => {
        const audio = state.metadata[id];
        await Promise.all(
          audio.chunks.map((chunkId) => this.deleteChunk(chunkId))
        );
      })
    );

    // Update store
    useAudioStorageStore.setState((state) => {
      const newMetadata = { ...state.metadata };
      toDelete.forEach((id) => delete newMetadata[id]);
      return {
        metadata: newMetadata,
        totalSize: state.totalSize - freedSpace,
      };
    });
  }

  private async deleteChunk(chunkId: string): Promise<void> {
    const db = await this.initDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['chunks'], 'readwrite');
      const store = transaction.objectStore('chunks');
      const request = store.delete(chunkId);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve();
    });
  }

  async cleanup(id: string): Promise<void> {
    const state = useAudioStorageStore.getState();
    const metadata = state.metadata[id];

    if (!metadata) return;

    // Delete all chunks
    await Promise.all(
      metadata.chunks.map((chunkId) => this.deleteChunk(chunkId))
    );

    // Update store
    useAudioStorageStore.setState((state) => {
      const newMetadata = { ...state.metadata };
      delete newMetadata[id];
      return {
        metadata: newMetadata,
        totalSize: state.totalSize - metadata.size,
      };
    });
  }
}

// Export a singleton instance for direct use
export const audioStorage = {
  async saveAudio(id: string, base64Data: string): Promise<string> {
    const storageManager = AudioStorageManager.getInstance();
    await storageManager.saveAudio(id, base64Data);
    return id;
  },

  async getAudio(id: string): Promise<string | null> {
    const storageManager = AudioStorageManager.getInstance();
    return await storageManager.getAudio(id);
  },

  async cleanup(id: string): Promise<void> {
    const storageManager = AudioStorageManager.getInstance();
    await storageManager.cleanup(id);
  }
}; 