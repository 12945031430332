import { createMachine } from 'xstate';

export const recordingMachine = createMachine({
  id: 'recording',
  initial: 'idle',
  states: {
    idle: {
      on: {
        START_RECORDING: 'recording'
      }
    },

    recording: {
      on: {
        PAUSE: 'paused',
        DISCARD: 'idle'
      }
    },

    paused: {
      on: {
        RESUME: 'recording',
        GENERATE: 'processing',
        DISCARD: 'idle'
      }
    },

    processing: {
      states: {
        saving: {
          on: {
            AUDIO_SAVED: 'uploading',
            ERROR: '#recording.error.saving_failed'
          }
        },
        uploading: {
          on: {
            UPLOAD_COMPLETE: 'transcribing',
            ERROR: '#recording.error.upload_failed'
          }
        },
        transcribing: {
          on: {
            TRANSCRIBE_COMPLETE: 'generating',
            ERROR: '#recording.error.transcribing_failed'

          }
        },
        generating: {
          on: {
            GENERATE_COMPLETE: '#recording.complete',
            ERROR: '#recording.error.generating_failed'
          }
        }
      },
      on: {
        DISCARD: '#recording.idle'
      },
      initial: 'saving'
    },

    complete: {
      id: 'complete',
      on: {
        SAVE: 'idle',
        DISCARD: 'idle'
      }
    },

    error: {
      states: {
        saving_failed: {
          on: {
            RETRY: '#recording.processing.saving'
          }
        },
        upload_failed: {
          on: {
            RETRY: '#recording.processing.uploading'
          }
        },
        transcribing_failed: {
          on: {
            RETRY: '#recording.processing.transcribing'
          }
        },
        generating_failed: {
          on: {
            RETRY: '#recording.processing.generating'
          }
        }

      },
      initial: 'saving_failed'
    }
  }
});