import React, { useState, useEffect } from 'react';
import { useStore, Note } from "@/lib/store";
import { NoteDetail } from '@/components/notes/NoteDetail';
import { NoteSettings } from '@/components/notes/NoteSettings';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card } from "@/components/ui/card";
import { useNavigate } from 'react-router-dom';
import { Search, Plus, Clock, MoreVertical } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { format, isToday, isYesterday, startOfDay } from 'date-fns';
import { track } from "@/analytics";

type View = 'list' | 'detail' | 'settings';

interface GroupedNotes {
  [key: string]: Note[];
}

const Tab2: React.FC = () => {
  const { notes, updateNotes, fetchNotes, updateNote } = useStore();
  const navigate = useNavigate();
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);
  const [currentView, setCurrentView] = useState<View>('list');

  // Add page view tracking
  useEffect(() => {
    track("View Notes List");
    fetchNotes();
    updateNotes();
  }, [updateNotes, fetchNotes]);

  const groupNotesByDay = (notes: Note[]): GroupedNotes => {
    return notes.reduce((groups: GroupedNotes, note) => {
      const date = new Date(note.updatedAt);
      let dateKey = format(startOfDay(date), date.getFullYear() === new Date().getFullYear() ? 'EEE MMM d' : 'EEE MMM d, yyyy');
      
      if (isToday(date)) {
        dateKey = 'Today';
      } else if (isYesterday(date)) {
        dateKey = 'Yesterday';
      }

      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(note);
      return groups;
    }, {});
  };

  const handleBack = () => {
    if (currentView === 'settings') {
      track("View Note Detail");
      setCurrentView('detail');
    } else {
      track("View Notes List");
      setCurrentView('list');
      setSelectedNote(null);
    }
  };

  const handleSettings = () => {
    track("View Note Settings");
    setCurrentView('settings');
  };

  const handleNoteClick = (note: Note) => {
    track("View Note Detail", {
      note_id: note.id,
      note_title: note.title
    });
    setSelectedNote(note);
    setCurrentView('detail');
  };

  const handleUpdate = async (updatedNote: Note) => {
    track("Update Note", {
      note_id: updatedNote.id,
      note_title: updatedNote.title
    });
    await updateNote(updatedNote);
  };

  if (currentView === 'settings' && selectedNote) {
    return <NoteSettings note={selectedNote} onBack={handleBack} onDelete={() => setCurrentView('list')} />;
  }

  if (currentView === 'detail' && selectedNote) {
    return (
      <NoteDetail 
        note={selectedNote} 
        onBack={handleBack}
        onSettings={handleSettings}
        handleUpdate={handleUpdate}
      />
    );
  }

  return (
    <div className="container mx-auto p-4 max-w-5xl space-y-6">
      {/* Header Section */}
      <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold">All Notes</h1>
          <p className="text-sm text-muted-foreground">
            {notes.length} {notes.length === 1 ? 'note' : 'notes'} total
          </p>
        </div>
        
        <div className="flex gap-2 w-full md:w-auto">
          <div className="relative flex-1 md:w-64">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
            <Input 
              disabled
              placeholder="Search notes..." 
              className="pl-9 w-full"
            />
          </div>
          <Button 
            onClick={() => navigate('/tab1')}
            className="flex items-center gap-2"
          >
            <Plus className="h-4 w-4" />
            New Note
          </Button>
        </div>
      </div>

      {/* Notes Grouped by Day */}
      {Object.entries(groupNotesByDay(notes)).map(([date, dateNotes]) => (
        <div key={date} className="space-y-4">
          <h2 className="text-lg font-semibold text-muted-foreground">{date}</h2>
          <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {dateNotes.map((note) => (
              <Card 
                key={note.id}
                className="group hover:shadow-lg transition-all cursor-pointer border bg-card"
                onClick={() => handleNoteClick(note)}
              >
                <div className="p-4 space-y-3">
                  {/* Note Header */}
                  <div className="flex justify-between items-start">
                    <h3 className="font-semibold line-clamp-2 flex-1 pr-4">
                      {note.title || 'Untitled Note'}
                    </h3>
                    <Button 
                      variant="ghost" 
                      size="sm" 
                      className="opacity-0 group-hover:opacity-100 -mt-1 -mr-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        // Add note options menu handler
                      }}
                    >
                      <MoreVertical className="h-4 w-4" />
                    </Button>
                  </div>

                  {/* Note Preview */}
                  <p className="text-sm text-muted-foreground line-clamp-3">
                    {note.content || 'No content'}
                  </p>

                  {/* Note Metadata */}
                  <div className="flex items-center gap-4 text-xs text-muted-foreground pt-2">
                    <div className="flex items-center gap-1">
                      <Clock className="h-3 w-3" />
                      <span>
                        {formatDistanceToNow(new Date(note.updatedAt), { addSuffix: true })}
                      </span>
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      ))}

      {/* Empty State */}
      {notes.length === 0 && (
        <div className="text-center py-12">
          <div className="text-muted-foreground mb-4">No notes yet</div>
          <Button 
            onClick={() => navigate('/tab1')}
            className="flex items-center gap-2"
          >
            <Plus className="h-4 w-4" />
            Create your first note
          </Button>
        </div>
      )}
    </div>
  );
};

export default Tab2; 