/* eslint-disable @typescript-eslint/no-explicit-any */
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { config } from "@/config";

type LogLevel = 'debug' | 'info' | 'warn' | 'error';

interface LogEntry {
  timestamp: string;
  level: LogLevel;
  message: string;
  data?: Record<string, any>;
}

class CapacitorLogger {
  private logPath = 'app-logs.txt';
  private level: LogLevel;

  constructor() {
    this.level = config.production ? 'info' : 'debug';
  }
  private async writeToFile(entry: LogEntry) {
    try {
      let logLine = `${entry.timestamp} ${entry.level}: ${entry.message}\n`;
      if (entry.data) {
        logLine += `${JSON.stringify(entry.data)}\n`;
      }
      
      await Filesystem.appendFile({
        path: this.logPath,
        data: logLine,
        directory: Directory.Data,
        encoding: Encoding.UTF8
      });
    } catch (error) {
      console.error('Failed to write log:', error);
    }
  }

  private createLogEntry(level: LogLevel, message: string, data?: Record<string, any>): LogEntry {
    return {
      timestamp: new Date().toISOString(),
      level,
      message,
      data
    };
  }

  private shouldLog(level: LogLevel): boolean {
    const levels: LogLevel[] = ['debug', 'info', 'warn', 'error'];
    return levels.indexOf(level) >= levels.indexOf(this.level);
  }

  async log(level: LogLevel, message: string, data?: Record<string, any>) {
    if (!this.shouldLog(level)) return;

    const entry = this.createLogEntry(level, message, data);
    await this.writeToFile(entry);

    // Console logging in non-production
    if (!config.production) {
      const consoleMethod = console[level] || console.log;
      if (data !== undefined) {
        consoleMethod(message, data);
      } else {
        consoleMethod(message);
      }
    }
  }

  // Convenience methods that match your current usage
  async info(message: string, data?: Record<string, any>) {
    return this.log('info', message, data);
  }

  async debug(message: string, data?: Record<string, any>) {
    return this.log('debug', message, data);
  }

  async warn(message: string, data?: Record<string, any>) {
    return this.log('warn', message, data);
  }

  async error(message: string, data?: Record<string, any>) {
    return this.log('error', message, data);
  }

  async getLogs(): Promise<string> {
    try {
      const { data } = await Filesystem.readFile({
        path: this.logPath,
        directory: Directory.Data,
        encoding: Encoding.UTF8
      });
      return data as string;
    } catch (error) {
      console.error('Failed to read logs:', error);
      return '';
    }
  }

  async clearLogs(): Promise<void> {
    try {
      await Filesystem.deleteFile({
        path: this.logPath,
        directory: Directory.Data
      });
    } catch (error) {
      console.error('Failed to clear logs:', error);
    }
  }
}

// Create singleton instance
export const logger = new CapacitorLogger();

// Maintain compatibility with your current log function
export const log = (message: string, data?: Record<string, any>) => {
  return logger.info(message, data);
};

// Re-export for convenience
export const { getLogs, clearLogs } = logger;