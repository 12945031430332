import { useSelector } from '@xstate/react';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { useStore } from '@/lib/store';
import { useCallback, useEffect, useRef } from 'react';
import { track } from '@/analytics';
import { toast } from "sonner";
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { log } from '@/analytics';
import { RecordingContext, useRecordingDuration } from './recordingService';

export const useRecordingState = () => {
  const { 
    startRecording, 
    stopRecording,
    togglePause,
    uploadAudio,
    audioData,
    currentRecordingId,
    transcriptionSuccessful,
    setAudioData,
    setCurrentRecordingId,
    setTranscriptionSuccessful,
    currentDraft,
    generateTranscription,
    generateNotesFromRecording,
    saveDraft,
    updateDraft,
    createNewDraft,
    setNoteTakerState,
  } = useStore();

  const { startDuration, pauseDuration, resetDuration } = useRecordingDuration();

  const navigate = useNavigate();

  const service = RecordingContext.useActorRef();
  const previousState = useRef<string | null>(null);

  // Setup subscription for state changes once on mount
  useEffect(() => {
    const subscription = service.subscribe(state => {
      const currentStateValue = state.value as string;
      if (previousState.current !== currentStateValue) {
        log('Recording State Change', {
          from: previousState.current || 'initial',
          to: currentStateValue
        });
        previousState.current = currentStateValue;
      }
    });

    // Cleanup subscription on unmount
    return () => subscription.unsubscribe();
  }, [service]);

  // Memoized selectors to prevent unnecessary re-renders
  const isIdle = useSelector(service, state => 
    state.matches('idle')
  );

  const isRecording = useSelector(service, state => 
    state.matches('recording')
  );
  
  const isPaused = useSelector(service, state => 
    state.matches('paused')
  );
  
  const isProcessing = useSelector(service, state => 
    state.matches('processing')
  );

  const currentState = useSelector(service, state => 
    state.value
  );

  // Action handlers
  const handleStartRecording = useCallback(async () => {
    try {
      log('Starting Recording');
      await startRecording();
      console.log('Starting duration tracking');
      startDuration();
      track("Start Recording");
      await KeepAwake.keepAwake();
      service.send({ type: 'START_RECORDING' });
      log('Recording Started');
    } catch (error) {
      log('Recording Start Error', { error });
      toast.error("Failed to start recording");
      Sentry.captureException(error);
    }
  }, [service, startRecording, startDuration]);

  const handlePauseRecording = useCallback(async () => {
    try {
      log('Pausing Recording');
      await togglePause(true);
      pauseDuration();
      track("Pause Recording");
      await KeepAwake.allowSleep();
      service.send({ type: 'PAUSE' });
      log('Recording Paused');
    } catch (error) {
      log('Recording Pause Error', { error });
      toast.error("Failed to pause recording");
      Sentry.captureException(error);
    }
  }, [pauseDuration, service, togglePause]);

  const handleResumeRecording = useCallback(async () => {
    try {
      log('Resuming Recording');
      await togglePause(false);
      startDuration();
      track("Resume Recording");
      await KeepAwake.keepAwake();
      service.send({ type: 'RESUME' });
      log('Recording Resumed');
    } catch (error) {
      log('Recording Resume Error', { error });
      toast.error("Failed to resume recording");
      Sentry.captureException(error);
    }
  }, [service, startDuration, togglePause]);

  const handleSave = useCallback(() => {
    log('Saving Draft');
    saveDraft();
    navigate('/tab2');
    setNoteTakerState('clean');
    resetDuration();
    service.send({ type: 'SAVE' });
    log('Draft Saved');
  }, [saveDraft, navigate, setNoteTakerState, resetDuration, service]);


  const handleGenerateNotes = useCallback(async () => {
    function resetRecordingUploadState() {
      log('Resetting Recording Upload State');
      setAudioData(null);
      setCurrentRecordingId(null);
      setTranscriptionSuccessful(false);
      resetDuration();
    }
    
    try {
      log('Starting Notes Generation');
      track("Generate Notes");
      service.send({ type: 'GENERATE' });
      
      let recordingResult: {
        recordingId?: string;
        base64Data: string;
      }| null = { base64Data: audioData || '' };

      let recordingId: string | null = currentRecordingId;
      
      if (!audioData) {
        log('No Audio Data - Stopping Recording');
        recordingResult = await stopRecording();
        pauseDuration();

        if (!recordingResult) {
          log('Recording Failed - No Audio Data');
          toast.error("No audio data to generate notes from");
          Sentry.captureMessage("No audio data to generate notes from");
          service.send({ type: 'ERROR' });
          return;
        };
        setAudioData(recordingResult.base64Data);
        log('Audio Data Saved');
        service.send({ type: 'AUDIO_SAVED' });
      }

      try {
        if (!currentRecordingId) {
          log('Starting Audio Upload');
          ({ id: recordingId } = await uploadAudio(recordingResult!.base64Data, currentDraft?.content));
          setCurrentRecordingId(recordingId);
          log('Audio Upload Complete', { recordingId });
          service.send({ type: 'UPLOAD_COMPLETE' });
        }
      } catch (error) {
        log('Audio Upload Error', { error });
        toast.error("We were unable to upload your recording");
        Sentry.captureException(error);
        service.send({ type: 'ERROR' });
        return;
      }

      if (!transcriptionSuccessful) { 
        try {
          log('Starting Transcription');
          await generateTranscription(recordingId!);
          setTranscriptionSuccessful(true);
          log('Transcription Complete');
          service.send({ type: 'TRANSCRIBE_COMPLETE' });
        } catch (error) {
          log('Transcription Error', { error });
          toast.error("We were unable to generate transcription from recording");
          Sentry.captureException(error);
          service.send({ type: 'ERROR' });
          return;
        }
      }
      
      try {
        log('Generating Notes from Recording');
        const generatedNotes = await generateNotesFromRecording(
          recordingId!,
          currentDraft?.title,
          currentDraft?.content
        );
        log('Notes Generated', { generatedNotes });
        updateDraft({
          title: generatedNotes.title || currentDraft?.title || '',
          content: generatedNotes.content || currentDraft?.content || ''
        });
      } catch (error) {
        log('Notes Generation Error', { error });
        toast.error("We were unable to generate notes from recording");
        Sentry.captureException(error);
        service.send({ type: 'ERROR' });
        return;
      }

      service.send({ type: 'GENERATE_COMPLETE' });
      handleSave();

      resetRecordingUploadState();
      log('Notes Generation Complete');

      toast.success("Notes generated successfully");

    } catch (error) {
      log('Notes Generation Error', { error });
      service.send({ type: 'ERROR' });
      toast.error("Error generating notes");
      Sentry.captureException(error);
    }
  }, [setAudioData, setCurrentRecordingId, setTranscriptionSuccessful, resetDuration, service, audioData, currentRecordingId, transcriptionSuccessful, handleSave, stopRecording, pauseDuration, uploadAudio, currentDraft?.content, currentDraft?.title, generateTranscription, generateNotesFromRecording, updateDraft]);


  const handleDiscard = useCallback(async () => {
    console.log("handleDiscard");
    log('Discarding Recording');
    resetDuration();
    setAudioData(null);
    setCurrentRecordingId(null);
    setTranscriptionSuccessful(false);
    createNewDraft();
    await KeepAwake.allowSleep();
    await stopRecording();
    service.send({ type: 'DISCARD' });
    log('Recording Discarded');
  }, [service, resetDuration, stopRecording, setAudioData, setCurrentRecordingId, setTranscriptionSuccessful, createNewDraft]);


  const handleRetry = useCallback(() => {
    log('Retrying');
    service.send({ type: 'RETRY' });
    handleGenerateNotes();  
    log('Retry Sent');
  }, [service, handleGenerateNotes]);

  return {
    isIdle,
    isRecording,
    isPaused,
    isProcessing,
    currentState,
    handleStartRecording,
    handlePauseRecording,
    handleResumeRecording,
    handleGenerateNotes,
    handleSave,
    handleDiscard,
    handleRetry,
    send: service.send
  };
};