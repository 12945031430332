import * as React from "react"

import { Mic, List, Settings } from "lucide-react"

import { MuseMenuHeader } from "@/components/layouts/MuseMenuHeader"
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
  Sidebar,
  SidebarHeader,
  SidebarRail,
} from "@/components/ui/sidebar"
import { useNavigate } from "react-router-dom"
import { track } from "@/analytics"

const items = [
  {
    title: "Capture",
    url: "/tab1",
    icon: Mic,
    isActive: true,
  },
  {
    title: "Notes",
    url: "/tab2",
    icon: List,
  },
  {
    title: "Settings",
    url: "/tab3",
    icon: Settings,
  },
]

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const navigate = useNavigate()

  const { toggleSidebar } = useSidebar()

  const handleClick = (path: string, title: string, e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    track('Sidebar Item Clicked', {
      title,
    });
    navigate(path)
    if (window.innerWidth <= 768) {
      toggleSidebar()
    }
  };

  return (
    <Sidebar className="border-r-0" {...props}>
      <SidebarHeader>
        <MuseMenuHeader />
        <SidebarMenu>
      {items.map((item) => (
        <SidebarMenuItem key={item.title}>
          <SidebarMenuButton asChild>
            <a href={item.url} onClick={(e) => handleClick(item.url, item.title, e)}>
              <item.icon />
              <span>{item.title}</span>
            </a>
          </SidebarMenuButton>
        </SidebarMenuItem>
      ))}
    </SidebarMenu>
      </SidebarHeader>
      <SidebarRail />
    </Sidebar>
  )
}
